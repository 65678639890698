import React from "react";

export default function DistributorContent() {
  return (
    <div>
      <p>
        We are excited to offer you the opportunity to join our growing network
        of dealers, distributors, and super stockists. Partnering with us opens
        doors to lucrative business opportunities and the chance to represent
        high-quality products in your region
      </p>
      <p>
        To apply for dealership, distributorship, or super stockist
        opportunities, please fill out the contact form below or contact our
        partnership team directly at
        <a href="mailto:customerservice@rubyfood.com"> customerservice@rubyfood.com
        </a>{" "}
        /<a href="tel:+919361804366">+91 93618 04366</a>
      </p>
      <h4 className="main-heading">Why Partner with us?
      </h4>
      <ul>
        <li>* No Deposit Required
        </li>
        <li>* Dedicated Sales Team Support
        </li>
        <li>* Dedicated company Customer support
        </li>
        <li>* High Profit Margins
        </li>
        <p>FMCG distributors are most preferable
        </p>
      </ul>
    </div>



  );
}
